<template>
  <div class="container">
    <div class="letter">
      <span>亲爱的朋友：</span>
      <p>面对生活难题，不妨像解数独一样，一步步找到答案。</p>
      <p>每当迷茫，记住每个挑战都是成长的机会，每个空格都等待你的智慧。</p>
      <p>在数独的世界里寻找秩序与和谐，让这份智慧照亮你的人生之路。</p>
      <p class="closing">致以最美好的祝愿</p>
      <p class="date">{{formattedTime}}</p>
    </div>
    <div class="countdown-redirect">
      <h5>页面将在 {{ seconds }} 秒后自动跳转...</h5>
      <br>
      <a href="https://sudoku.injedu.com/Landpage/pay.html" class="game-button">马上开通</a>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CountdownRedirect',
    data() {
      return {
        seconds: 5, // 倒计时秒数
        timer: null, // 定时器
        formattedTime: ''
      }
    },
    mounted() {
      this.startCountdown();
    },
    beforeDestroy() {
      clearInterval(this.timer);
    },
    created() {
      this.updateTime();
    },
    methods: {
      startCountdown() {
        this.timer = setInterval(() => {
          if (this.seconds > 0) {
            this.seconds--;
          } else {
            this.redirectToPage();
          }
        }, 1000);
      },
      redirectToPage() {
        clearInterval(this.timer);
        window.location.href = 'https://sudoku.injedu.com/Landpage/pay.html';
      },
      updateTime() {
        this.formattedTime = this.formatDate(new Date());
      },
      formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}年${month}月${day}日`;
      }
    }
  }
</script>

<style scoped>
  .container {
    text-align: left;
    max-width: 70vw;
    padding: 20px;
    margin: 0 auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: white;
  }

  .letter {
    line-height: 1.6;
    margin-bottom: 1.5em;
    font-style: italic;
  }

  .letter p {
    text-indent: 2em;
  }

  .letter .salutation {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .letter .closing {
    margin-top: 1em;
    margin-bottom: 1em;
    text-align: right;
  }

  .letter .signature {
    margin-top: 1em;
    text-align: right;
  }

  .letter .date {
    text-align: right;
    margin-top: 1em;
  }

  .countdown-redirect {
    text-align: center;
    margin-top: 50px;
  }

  .game-button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 8px;
    transition-duration: 0.4s;
  }
</style>
